.masonry-grid{
	color: #fff;
	&__col{
		display: flex;
		flex-direction: column;
		padding: 0;
		min-height: 55vh;
		min-height: calc(55 * var(--vh, 1vh));
	}
	&__item{
		position: relative;
		direction: inline-block;
		flex-grow: 1;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 1;
		min-height: 400px;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			// background-color: #000;
			opacity: 0.10;
			z-index: -1;
		}
	}
	&__content{
		position: absolute;
		left: 40px;
		right: 40px;
		bottom: 40px;
	}
}