// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.


$primary: #287F4A; //#FAD300; // Thats a sample how you could change a BootStrap variable.
$light: #fff; // #F9F9F9;
$dark: #000; //#454848;

$gray-900: $dark;
$body-color: $dark !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1600px //1420px //1140px
);


$font-family-sans-serif:      museo-sans, sans-serif;
$font-family-base:            $font-family-sans-serif;


$spacer: 3rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4)
  ),
  $spacers
);
