// right to left (slight to up)
// zoom out
// down to up (slight to right)
// right to left, top to down

.ha-container{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
}

.ha-item{
	//animation-fill-mode: forwards;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-size: cover;
	opacity: 0;
	transition: opacity 0s;
	will-change: transform, opacity;
	&.previous{
		opacity: 1;
	}
	&.active{
		opacity: 1;
		transition: opacity 4s;
	}
}

.ha-right-to-left{
	right: ((100 / (1 - 0.2)) - 100) * -1%; // 0.2 = 20%
	bottom: ((100 / (1 - 0.05)) - 100) * -1%;
	&.active, &.previous{
		animation: ha-right-to-left 8s linear;
	}
	@keyframes ha-right-to-left{
		0%{
			transform: translate(-20%, -5%);
		}
		100%{
			transform: translate(0%, 0%);
		}
	}
}

.ha-zoom-out{
	&.active, &.previous{
		animation: ha-zoom-out 8s linear;
	}
	@keyframes ha-zoom-out{
		0%{
			transform: scale(1.4);
		}
		100%{
			transform: scale(1);
		}
	}
}

.ha-down-to-up{
	left: ((100 / (1 - 0.05)) - 100) * -1%; // 0.2 = 20%
	bottom: ((100 / (1 - 0.2)) - 100) * -1%;
	&.active, &.previous{
		animation: ha-down-to-up 8s linear;
	}
	@keyframes ha-down-to-up{
		0%{
			transform: translate(5%, -20%);
		}
		100%{
			transform: translate(0%, 0%);
		}
	}
}

.ha-rtl-ttd{ // right to left, top to down
	right: ((100 / (1 - 0.2)) - 100) * -1%; // 0.2 = 20%
	bottom: ((100 / (1 - 0.2)) - 100) * -1%;
	&.active, &.previous{
		animation: ha-rtl-ttd 8s linear;
	}
	@keyframes ha-rtl-ttd{
		0%{
			transform: translate(-20%, -20%);
		}
		100%{
			transform: translate(0%, 0%);
		}
	}
}