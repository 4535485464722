	
// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here

html{
	// font-size: 80%;
	font-size: 80%;
	@media (min-width: 760px){
		font-size: 100%;
	}
}

body{
	background-color: #fff;
	// padding-top: 100px;
	font-size: 1em;
	font-weight: 500;
	line-height: 1.875em;
}
strong{
	font-weight: 700;
}

.text-sm{
	font-size: 0.8em;
}

.icon-small{
	svg{
		height: 32px !important;
		width: auto !important;
	}
}

.section-nav-padding{
	padding-top: calc(var(--nav-height, 0) + 2rem);
	padding-bottom: 2rem;
}

.text-thin{
	&, p{
		font-weight: 300;
	}
}

@import '_header-animations.scss';

.table-small{
	max-width: 100%;
	td, th{
		padding: 5px 20px 5px 0;
		font-size: 0.8em;
	}
}

%anchor{
	position: relative;
	text-decoration: none;
	white-space: nowrap;
	&:before{
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		right: 100%;
		margin-top: 0.1em;
		border-bottom: 0.125em solid $primary;
		border-bottom: 0.125em solid currentColor;
		transition: all 0.25s;
	}
	&:hover{
		text-decoration: none;
		&:before{
			right: 0;
		}
	}
}
p a{
	// @extend %anchor;
	&, &:hover, &:focus, &:active{
		color: inherit;
	}
}
p a, .arrow--right{
	// margin-left: 0.5em;
	/*&:after{
		content: '→';
		font-size: 1rem;
		margin-left: 0.5em;
		margin-right: 0.5em;
		vertical-align: middle;
		line-height: 1em;
	}*/
}

.breadcrumbs-nav{
	background-color: #F2F2F2;
}

.list--clean{
	list-style: none;
	margin: 0;
	padding: 0;
	li{
		margin: 0;
		padding: 0;
	}
	a{
		color: inherit;
		display: inline-block;
		text-decoration: none;
		padding: 0 !important;
		transition: all 0.3s;
		&:hover{
			opacity: 0.7;
		}
	}
}

.btn-with-icon{
	position: relative;
	padding-left: 1.5em !important;
	padding-right: 1.5em !important;
	&:before{
		content: '-';
		visibility: hidden;
	}
	svg{
		position: absolute;
		top: 50%;
		left: 50%;
		height: 1em;
		width: 1em;
		transform: translate(-50%, -50%);
	}
	svg *{
		fill: currentColor;
	}
	&--offset-top{
		svg{
			height: 1.25em;
			width: 1.25em;
			transform: translate(-50%, -60%);
		}
	}

	&:focus{
		box-shadow: none !important;
	}
}

.navbar-dark .navbar-toggler{
	border-color: transparent;
}
.navbar-dark .navbar-toggler-icon{
	background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%270%200%2030%2030%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20stroke%3D%27rgba%280%2C0%2C0%2C%200.5%29%27%20stroke-width%3D%272%27%20stroke-linecap%3D%27round%27%20stroke-miterlimit%3D%2710%27%20d%3D%27M4%207h22M4%2015h22M4%2023h22%27%2F%3E%3C%2Fsvg%3E);
}

.navbar-brand{
	width: 300px;
	max-width: 35%;
}

.navbar-dark .dropdown-toggle::after{
	display: none;
}
.navbar-dark{
	.dropdown-menu{
		border-radius: 0;
		li{
			padding: 0 !important;
		}
	}
}

.stroke--left{
	position: relative;
	&:before{
		content: '';
		position: absolute;
		top: 0; //-30px;
		left: -40px;
		height: 100%;
		min-height: 60px;
		margin-left: -7px;
		width: 7px;
		// border-radius: 7px;
		background-color: $primary;
	}
	&--short{
		@extend .stroke--left;
		&:before{
			top: 50%;
			bottom: auto;
			height: 100px;
			margin-top: -50px;
		}
	}
	strong{
		display: block;
		&:after{
			content: '';
			display: block;
			border-top: 1px solid #000;
			width: 30px;
			margin: 1em 0 1.5em 0;
		}
	}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
	font-weight: 400;
	letter-spacing: 0.15em; //0.15em;
	margin-bottom: 0.75em;
	em{
		font-style: normal;
		font-weight: 500;
	}
}

h1, .h1{
	font-size: 2.25em;
	// text-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);

	@media (min-width: 720px){
		font-size: 2.25em;
	}
	@media (min-width: 1300px){
		font-size: 2.25em; //2.5rem;
	}
}

h2, .h2{
	font-size: 2.25em;
	// text-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);

	@media (min-width: 720px){
		font-size: 2.25em;
	}
	@media (min-width: 1300px){
		font-size: 2.25em; //2.5rem;
	}
}
h3, .h3{
	font-size: 1.5rem;
	// text-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);

	@media (min-width: 720px){
		font-size: 2rem;
	}
	@media (min-width: 1300px){
		font-size: 2.5rem;
	}
}

.display-1, .display-2, .display-3, .display-4{
	font-weight: bold;
}
/*
h1{
	font-size: 4em;
}
h2{
	font-size: 2.5em;
}
h3{
	font-size: 1.75em;
}
*/

[data-transition]:not([data-transition-done]){
	visibility: hidden;
}

.container{
	// padding: 0 20px;
}
[class*=col-]:not([class*=col-100]){
	padding: 0 20px; //40px;
}

.wrapper{
	padding: 0;
	max-width: 100vw;
	overflow: hidden;
}

.dynamic-columns{
	@media (min-width: 980px){
		column-count: 2;
		column-gap: 80px;
	}
}

#map{
	height: 50vh;
	width: 100%;
	position: relative;
}

$marker_size: 20px;
.marker {
	position: relative;
	width: $marker_size;
	height: $marker_size;
	overflow: visible;
	cursor: pointer;
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: (236 / 32) * $marker_size;
		height: $marker_size;
		background-image: url('../images/logo.svg');
		background-size: cover;
	}
}

.header, .hero{
	position: relative;
	background: #fff;
	display: flex;
	padding: 20px 0;
	padding-bottom: 100px;
	height: 80vh;
	height: calc(80 * var(--vh, 1vh));
	align-items: center;
	overflow: hidden;
	z-index: 0;
}

.hero{
	text-align: center;
	&-content{
		padding: 3em 2.5em 1.5em 2.5em;
		max-width: 95%;
		margin: 0 auto;
		@media (min-width: 720px){
			padding: 4em 5em 2em 5em;
		}
	}
}

section, .section{
	@extend .mb-5;
	margin: 0;
	display: block;
	position: relative;
	overflow: hidden;
	z-index: 0;
}

.image-margins{
	img{
		@extend .m-2;
	}
}

a.section{
	text-decoration: none;
	transition: opacity 0.3s linear;
	&:hover, &:focus, &:active{
		text-decoration: none;
		opacity: 0.5;
	}
}

.container-sm{
	@extend .container;
	max-width: 1000px;
}

.lead{
	font-size: 1.25em;
	font-weight: 600;
}

// Nav
#wrapper-navbar{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	background-color: rgba(#fff, 0.9);
	transition: all 0.3s;
	// top: var(--navbar-push, 0);
	.navbar{
		padding: 1rem 3rem;
		transition: padding 0.3s;
	}
	.navbar-main{
		transition: all 0.3s;
	}
	.menu-item{
		padding: 0 20px;
	}
	svg{
		height: 60px; width: auto;
	}
	.menu-item a{
		position: relative;
		color: #fff;
		font-size: 1rem;
		font-weight: 500;
		letter-spacing: 0.02em;
		transition: all 0.2s;
		&:after{
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			right: 100%;
			height: 3px;
			background-color: $primary;
			border-bottom: 1px solid darken($primary, 5);
			transition: all 0.4s;
		}
		&:hover, &:focus, &:active{
			color: darken(#fff, 50) !important;
			&:after{
				right: 0%;
			}
		}
	}
	.menu-item a{
		color: #000;
		&:hover, &:focus, &:active{
			color: lighten(#000, 50) !important;
			&:after{
				right: 0%;
			}
		}
	}

	// State
	&.scrolled{
		box-shadow: 0 5px 5px 0 rgba(0,0,0,0.1);
		.navbar{
			padding: 1rem 3rem;
		}
		.navbar-main{
			// @extend .py-2;
		}
	}

	@include media-breakpoint-down(sm){
		.navbar-main{
			transition: all 0.2s;
		}
		&.open{
			.navbar-main{
				padding: 40px 0 0 !important;
			}
		}
		.navbar-collapse{
			.navbar-nav{
				padding: 40px 0;
				.menu-item{
					padding: 0;
					opacity: 0;
					transform: translateY(40px);
					transition: all 0.2s;

					@for $i from 1 through 10{
						&:nth-child(#{$i}){
							transition-delay: 0.1s * $i;
						}
					}

					a{
						display: inline-block;
						font-size: 1.5em;
						padding: 5px 0;
						padding-right: 40px;
						margin: 15px 0;
						&:after{
							border-bottom: 4px solid $primary;
						}
					}
				}
			}

			&.show{
				.menu-item{
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}
}

.menu-vertical{
	list-style: none;
	margin: 0;
	padding: 0;
	li{
		a{
			text-decoration: none;
			position: relative;
			display: inline-block;
			padding: 0;
			margin-bottom: 5px;
			color: inherit;
			&:before{
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				right: 100%;
				border-bottom: 2px solid #000;
				border-bottom: 2px solid currentColor;
				transition: all 0.2s;
			}
			&:hover, &:focus, &:active{
				color: inherit;
				&:before{
					right: 0;
				}
			}
		}
	}
}

// Buttons
.btn{
	padding: 0.75em 2em;//1em 2em;
	border-width: 2px;
	// border-radius: 10px;
	font-weight: 300;
	border-radius: 0;
}
.btn-outline-primary{
	color: $primary;
	border-color: $primary;
	background-color: transparent;
	&:hover, &:active, &:focus{
		color: #fff;
	}
}

.input-group{
	border-radius: 3px;
	overflow: hidden;
	box-shadow: 0 5px 5px 0 rgba(0,0,0,0.05);
	transition: all 0.2s;
	.form-control{
		padding: 0 1em;//1em 2em;
		line-height: (0.75em * 2) + 1em;
		height: 2.5em + 0.75em;
		border-width: 0;
		border-radius: 0;
		background-color: #F2F2F2;
		text-align: left;
		transition: all 0.2s;

		&:focus{
			background-color: darken(#F2F2F2, 2);
		}
	}

	&:focus-within{
		box-shadow: 0 5px 5px 0 rgba(0,0,0,0.1);
	}
}

// Parallax
.parallax-background{
	z-index: -1;
	div{
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	&, div{
		position: absolute;
		top: -10%;
		left: 0;
		right: 0;
		bottom: -10%;
	}
}

.logo-icon{
	opacity: 0.25;
	div{
		background-image: url(../images/logo-icon.png); 
		background-size: contain; 
		background-position: right;
	}

	@include media-breakpoint-up(md){
		opacity: 1;
	}
}

// Sizing
.max-width{
	width: 100%;
	max-width: 550px;
	&-xs{
		width: 100%;
		max-width: 300px;
	}
	&-md{
		width: 100%;
		max-width: 450px;
	}
	&-lg{
		width: 100%;
		max-width: 1000px;
	}
}

// Masonry grid
@import 'masonry-grid';

.grid__item{
	position: relative;
	background-position: center;
	background-size: 100% auto;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
	&__sizer{
		width: 100%;
	}
	&__content{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		background-color: rgba(#000, 0.4);
		color: #fff;
		text-align: center;
		padding: 20px;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		opacity: 1;
		transition: all 0.2s;
	}
	&:hover, &:focus, &:active{
		.grid__item__content{
			background-color: rgba($primary, 0.4);
		}
	}
}

.styled-anchor{
	position: relative;
	display: inline-block;
	font-weight: bold;
	transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
	&, &:active, &:focus, &:hover{
		color: inherit;
		text-decoration: none;
	}
	&:after{
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		border-bottom: 2px solid $primary;
		transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
	}
	&:hover, &:focus, &:active{
		// color: $primary;
		transform: scale(0.99);
		&:after{
			// box-shadow: inset 0 4px 0 0 $primary;
			right: 10px;
			left: 10px;
		}
	}
}

// Arrow
.arrow{
	&-left, &-right{
		position: absolute;
		width: 40px;
		height: 40px;
		background: #f5f5f5;
		left: 50%;
		bottom: -25px;
		margin-left: -25px;
		transform: rotate(45deg);
	}
	@include media-breakpoint-up(md){
		&-left, &-right{
			top: 70%;
			left: -25px;
			margin-left: auto;
			bottom: auto;
		}
		&-right{
			left: auto;
			right: -25px;
		}
	}
}

// Footer
footer{
	font-size: 1rem;
	svg{
		height: 60px; width: auto;
	}
}

// Some helpers
.with--lead p:first-of-type{
	@extend .lead;
}

.image-holder{
	overflow: hidden;
	height: 300px;
	margin-bottom: 20px;
	img{
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.language-picker{
	a{
		display: inline-block;
		opacity: 0.5;
		transition: all 0.2s;
		&:hover, &:active, &:focus{
			opacity: 1;
		}
	}
	svg{
		height: 25px;
		width: auto;
		margin: 0 2px;
	}
}


// Grid shortcode plugin spacing
.grid-column-row{
	margin: 0 -2em !important;
}
.inside-grid-column{
	padding: 0 2em 0 2em !important;
}


// Product cats
.item-grid{
	@extend .row;
	&__item{
		@extend .col-lg-3, .col-md-4, .col-sm-6;
		display: inline-block;
		padding: 10px 10px;
		transition: filter 0.2s linear;
		h3{
			position: relative;
			display: inline-block;
			// font-size: calc(1.5vw + 1.5vh);
			// font-size: 2.5rem;
			&:after{
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				right: 100%;
				margin-top: 10px;
				border-bottom: 5px solid #000;
				transition: right 0.6s cubic-bezier(0.16, 1, 0.3, 1);
			}
		}
		&:hover, &:focus, &:active{
			filter: contrast(1.2) brightness(0.95);
			h3:after{
				right: 0;
			}
		}
	}
	&__sizer{
		display: block;
		position: relative; 
		padding-bottom: 100%; 
		background-size: auto 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	&__content{
		position: absolute; 
		left: 40px; 
		right: 40px; 
		bottom: 20px; 
		z-index: 1;
	}
}

// Contact page profile boxes
.profile-box{
	@extend .col-12, .col-md-6, .col-lg-4;
	margin-bottom: 30px;
	&__inner{
		position: relative;
		padding-bottom: 90%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		z-index: 1;
		box-shadow: 0 0 10px -5px rgba(0,0,0,0.5);
		overflow: hidden;
		transition: box-shadow 0.2s;
	}
	&__title{
		position: absolute;
		left: 20px;
		bottom: 20px;
		transition: all 0.6s 0.15s cubic-bezier(0.16, 1, 0.3, 1);
		> *{
			color: #fff;
			background-color: rgba(0,0,0,0.4);
			padding: 5px 10px;
		}
	}
	&__hover{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(#1C3A42, 0.75);
		color: #fff;
		z-index: -1;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 0.75em;
		opacity: 0;
		transform: scale(1.1);
		transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
	}

	&:hover, &:active, &:focus{
		.profile-box__title{
			opacity: 0;
			transform: scale(0.8);
			transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
		}
		.profile-box__hover{
			opacity: 1;
			transform: scale(1);
			transition: all 0.6s 0.15s cubic-bezier(0.16, 1, 0.3, 1);
		}
		.profile-box__inner{
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.4);
		}
	}
}


// Mapbox
.marker {
  background-image: url('mapbox-icon.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #199874;
  box-shadow: 0 0 0 2px rgba(#135A48, 0.7), inset 0 0 0 2px #FFF;
  cursor: pointer;
}



// Gallery
.gallery-items{
	> div{
		opacity: 0;
		transform: translateX(50%);
		transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
		&.active{
			opacity: 1;
			transform: translateX(0);
			transition: all 0.6s 0.4s cubic-bezier(0.16, 1, 0.3, 1);
		}
	}
}
.gallery-controls{
	position: relative;
	list-style: none;
	padding: 0;
	margin: 0;
	li{
		display: inline-block;
		padding: 0;
		margin: 0;

		a{
			&:hover{
				opacity: 0.75;
			}
		}

		&.prev, &.next{
			position: absolute;
			top: 50px;
			margin-top: -1em;
			line-height: 2em;
			height: 2em;
			left: -1em;
		}
		&.next{
			left: auto;
			right: -1em;
		}
		&.item a{
			display: block;
			width: 100px;
			height: 100px;
			transition: opacity 0.3s;
		}
		&.active a{
			opacity: 0.5;
		}
	}
}


// Slider
.slider-progress{
	position: absolute;
	left: 0;
	right: 100%;
	bottom: 0;
	height: 6px;
	background-color: rgba($primary, 1);
	z-index: 1000;
}
.slider-section{
	position: relative;
	overflow: hidden;
	margin: 0 auto !important;
	transition: height 0.6s cubic-bezier(0.16, 1, 0.3, 1);
	&__slide{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
	}
	&__nav{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 5px;
		list-style: none;
		margin: 0;
		padding: 0;
		z-index: 100;
		text-align: center;
		li{
			margin: 0;
			padding: 0;
			display: inline-block;
			a{
				display: block;
				width: 20px;
				height: 20px;
				background-color: rgba(#fff, 0.25);
				margin: 5px;
				border-radius: 5px;
				box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
				transition: all 0.2s;
				&:hover{
					background-color: rgba(#fff, 0.5);
					box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
				}
				&.active{
					background-color: #fff;
					box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
				}
			}
		}
	}
}

// Iframe
.iframe-container{
	text-align: center;
	iframe{
		width: 100%;
		height: 500px;
		max-width: 889px;
		box-shadow: 0 20px 40px -10px rgba(0,0,0,0.5);
	}
}

// LGC Fix
.lgc-first .inside-grid-column {
    padding-left: 0 !important;
}
.lgc-last .inside-grid-column {
    padding-right: 0 !important;
}


// Floating buttons
.floating-buttons{
	position: fixed;
	top: 25%;
	right: 0;
	transform: translateY(-50%);
	z-index: 100000;
}
.floating-button{
	position: relative;
	display: block;
	background-color: #fff;
	width: 55px;
	height: 55px;
	text-align: center;
	margin: 5px 0;

	border-radius: 5px;
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);

	@supports (display: flex){
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&, &:hover, &:focus, &:active{
		color: #000;
		text-decoration: none;
	}
	img{
		position: relative;
		width: 30px;
		height: auto;
		z-index: 1;
	}
	&__content{
		display: block;
		position: absolute;
		background-color: #fff;
		top: 0;
		right: 0;
		max-width: 90vw;
		font-size: 0.8em;
		line-height: 1.4em;
		padding: 20px 20px;
		border-radius: 5px;
		box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
		transform-origin: top right;
		transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
		pointer-events: none;
		text-align: left;

		transform: scale(0.8);
		opacity: 0;

		p{
			margin-bottom: 5px;
		}
		table{
			margin: 0 -10px;
		}
		table td{
			white-space: nowrap;
			padding: 5px 10px;
		}
	}
	&:hover, &:active, &:focus{
		z-index: 100;
		.floating-button__content{
			transform: scale(1);
			opacity: 1;
		}
	}
}

// Contact form 7
.wpcf7 form{
	display: flex;
	flex-wrap: wrap;
	input[type=text], input[type=email], input[type=number], textarea{
		background-color: #F1F1F1;
		border: 0;
		font-size: 1em;
		padding: 1em;
		height: auto;
	}
	p{
		width: 50%;
		flex: 1 1 50%;
		margin: 0 !important;
		padding: 7px;
		&:last-of-type{
			flex: 1 1 100%;
			input{
				@extend .btn, .btn-primary, .btn-lg;
				float: right;
				line-height: 1em;
				height: 3em;
				display: inline-block;
				width: auto;
			}
		}
	}
}



.page-id-565 [class*="col-"] img{
	box-shadow: 0 20px 40px -10px rgba(0,0,0,0.8);
	border-radius: 10px;
}
.input-group{
	box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
	border-radius: 5px;
}
.input-group:focus-within{
	box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);
	border-radius: 5px;
}

li.product{
	transition: all 0.15s;
}

.widget{
	margin-bottom: 2em;
}
.widget-title{
	font-size: 0.75em;
	font-weight: bold;
	background-color: #eaeaea;
	padding: 15px;
}

/* Login forms etc in woocommerce */
.woocommerce-Input{
	@extend .form-control;
	width: 100%;
}
.password-input{
	width: 100%;
}